import { FormattedMessage } from 'react-intl';

import parentStyles from './CreatePasswordPage.module.scss';
import styles from './CreatePasswordSuccess.module.scss';
import { DownloadAppLinks } from './DownloadAppLinks';

export function CreatePasswordSuccess() {
  return (
    <div className={styles.content}>
      <h1 className={parentStyles.title}>
        <FormattedMessage
          id="create-password-page.success-title"
          defaultMessage="Please download the app."
        />
      </h1>
      <p>
        <FormattedMessage
          id="create-password-page.success-message"
          defaultMessage="Then, sign in and complete your profile information."
        />
      </p>

      <DownloadAppLinks />
    </div>
  );
}
