import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router-dom';

import logo from '../../logo.svg';

import styles from './Layout.module.scss';

export function Layout() {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.logo}>
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <Outlet />
      </div>
      <div>
        <FormattedMessage
          id="app.email-help"
          defaultMessage="For help, email {helpEmail}"
          values={{
            helpEmail: <a href="mailto:help@allurion.com">help@allurion.com</a>,
          }}
        />
      </div>
    </div>
  );
}
