import { FormattedMessage } from 'react-intl';

import styles from './HomePage.module.scss';

export function HomePage() {
  return (
    <div className={styles.content}>
      <h2>
        <FormattedMessage
          id="homepage.wellcome"
          defaultMessage="We are creating a new onboarding experience for our clients. {br} Check again soon"
          values={{
            br: <br />,
          }}
        />
      </h2>

      <a
        className={styles.link}
        href="https://www.allurion.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FormattedMessage id="homepage.website-link" defaultMessage="Check our website" />
      </a>
    </div>
  );
}
