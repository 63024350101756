import { RudderAnalytics } from '@rudderstack/analytics-js';

const analytics = new RudderAnalytics();
const REACT_APP_RUDDER_WRITE_KEY = import.meta.env.REACT_APP_RUDDER_WRITE_KEY;
const REACT_APP_RUDDER_DATA_PLANE_URL = import.meta.env.REACT_APP_RUDDER_DATA_PLANE_URL;

if (!REACT_APP_RUDDER_WRITE_KEY) {
  throw new Error('Missing REACT_APP_RUDDER_WRITE_KEY environment variable');
}

if (!REACT_APP_RUDDER_DATA_PLANE_URL) {
  throw new Error('Missing REACT_APP_RUDDER_DATA_PLANE_URL environment variable');
}

analytics.load(REACT_APP_RUDDER_WRITE_KEY, REACT_APP_RUDDER_DATA_PLANE_URL);

const userAlreadyTracked = new Set<string>();

export function trackUser(userId: string, traits: any) {
  if (userAlreadyTracked.has(userId)) {
    return;
  }

  analytics.identify(userId, traits);
  userAlreadyTracked.add(userId);
}
