import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { FormGroup } from '../shared/components/FormGroup';
import { Loader } from '../shared/components/Loader';
import { PasswordInput } from '../shared/components/PasswordInput';
import { usePatientUser } from '../shared/hooks/usePatientUser';

import { CreatePasswordErrorFallback } from './CreatePasswordErrorFallback';
import styles from './CreatePasswordForm.module.scss';
import parentStyles from './CreatePasswordPage.module.scss';

const schema = yup
  .object({
    email: yup.string().required(),
    password: yup
      .string()
      .required()
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/),
  })
  .required();

type FormValues = {
  email: string;
  password: string;
};

export function CreatePasswordForm() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const username = searchParams.get('username');
  const confirmationCode = searchParams.get('code');

  const { user, setPassword } = usePatientUser(username);
  const [isProcessing, setIsProcessing] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async ({ password }: any) => {
      setIsProcessing(true);
      await setPassword(password, confirmationCode).catch(() => {
        //FIXME: show a toast message with the error
      });
      setIsProcessing(false);

      navigate('success');
    },
    [navigate, setPassword, confirmationCode]
  );

  if (!username) {
    return <CreatePasswordErrorFallback error={new Error('empty username')} />;
  }

  return (
    <div className={styles.container}>
      <h1 className={parentStyles.title}>
        <FormattedMessage
          id="create-password-page.title"
          defaultMessage="Finish registering your account"
        />
      </h1>
      <form className={styles.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <FormGroup error={errors['email']}>
          <label htmlFor="userEmail">
            <FormattedMessage
              id="create-password-page.email-input-label"
              defaultMessage="Your email address"
            />
            <Link
              to={{ pathname: 'change-email', search: searchParams.toString() }}
              className={styles.changeEmailLink}
            >
              <FormattedMessage
                id="create-password-page.change-email-link"
                defaultMessage="Change email"
              />
            </Link>
          </label>
          <input
            id="userEmail"
            type="email"
            {...register('email')}
            defaultValue={user?.email}
            readOnly
          />
        </FormGroup>

        <FormGroup
          error={errors['password']}
          errorMessages={{
            required: (
              <FormattedMessage
                id="create-password-page.password-input-required"
                defaultMessage="Please create a password"
              />
            ),
            matches: (
              <>
                <strong>
                  <FormattedMessage
                    id="create-password-page.password-input-error"
                    defaultMessage="Please make sure your password contains:"
                  />
                </strong>
                <PasswordHints />
              </>
            ),
          }}
          help={
            <>
              <FormattedMessage
                id="create-password-page.password-input-help"
                defaultMessage="Password must contain:"
              />
              <PasswordHints />
            </>
          }
        >
          <label htmlFor="userPassword">
            <FormattedMessage
              id="create-password-page.password-input-label"
              defaultMessage="Create password"
            />
          </label>
          <PasswordInput id="userPassword" {...register('password')} />
        </FormGroup>

        <div className={styles.actions}>
          <button type="submit" disabled={isProcessing}>
            <FormattedMessage id="create-password-page.form-submit-btn" defaultMessage="Continue" />
          </button>
        </div>
      </form>
      {isProcessing && <Loader fullscreen />}
    </div>
  );
}

function PasswordHints() {
  return (
    <ul>
      <li>
        <FormattedMessage
          id="create-password-page.password-hint-min-size"
          defaultMessage="At least eight characters"
        />
      </li>
      <li>
        <FormattedMessage
          id="create-password-page.password-hint-letter"
          defaultMessage="At least one capital letter"
        />
      </li>
      <li>
        <FormattedMessage
          id="create-password-page.password-hint-number"
          defaultMessage="At least one number"
        />
      </li>
      <li>
        <FormattedMessage
          id="create-password-page.password-hint-special-chars"
          defaultMessage="At least one special character: {specialChars}"
          values={{
            specialChars: '!@#$%^&*()_+:',
          }}
        />
      </li>
    </ul>
  );
}
