import { convertBrowserLocale, isRtlLocale } from '@allurion/utils';
import * as Sentry from '@sentry/react';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';

import { getSupportedTranslationsByLanguage } from './translations';

export function LocaleProvider({ children }: PropsWithChildren<{}>) {
  const locale = navigator.language;

  const messages = useMemo(() => {
    try {
      const localizeLocale = convertBrowserLocale(locale);
      const translations = getSupportedTranslationsByLanguage();

      return translations[localizeLocale];
    } catch (err) {
      Sentry.captureException(err);
    }
  }, [locale]);

  useEffect(() => {
    const documentElement = document.documentElement;

    if (documentElement.getAttribute('lang') !== locale) {
      documentElement.setAttribute('lang', locale);
    }

    if (!documentElement.getAttribute('dir')) {
      documentElement.setAttribute('dir', isRtlLocale(locale) ? 'rtl' : 'ltr');
    }

    if (!messages) {
      Sentry.captureException(new Error(`Missing translations for locale: ${locale}`));
    }
  }, [locale, messages]);

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
