import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useEffect } from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

export function setupSentry() {
  const SENTRY_DSN = 'https://4cecb01a08dc4702aacc329323a0c2f8@o1024506.ingest.sentry.io/6459893';

  Sentry.init({
    dsn: SENTRY_DSN,
    environment: import.meta.env.REACT_APP_ALLURION_ENV ?? 'local',
    release: `patient-onboarding-app@${import.meta.env.REACT_APP_GIT_SHA ?? 'local'}`,
    initialScope: {
      tags: {
        'app.locale': navigator.language,
      },
    },
    integrations: [
      new BrowserTracing({
        tracingOrigins: [
          '*',
          // "api.setup.dev-allurion.com",
          // "api.setup.qa-allurion.com",
          // "api.setup.allurion.com",
        ],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes as any
        ),
      }),
      new ExtraErrorDataIntegration({ depth: 3 }),
      new CaptureConsoleIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
