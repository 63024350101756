import { FormattedMessage } from 'react-intl';

import logo from '../../logo.svg';

import styles from './GenericErrorFallback.module.scss';

export function GenericErrorFallback() {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.logo}>
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <div className={styles.content}>
          <strong>
            <FormattedMessage
              id="app.generic-error-title"
              defaultMessage="It looks like we're having some internal issues"
            />
          </strong>

          <p>
            <FormattedMessage
              id="app.generic-error-message"
              defaultMessage="Our team has been notified. If you need aditional help please send us an email"
            />
          </p>
        </div>
        <div>
          <FormattedMessage
            id="app.email-help"
            defaultMessage="For help, email {helpEmail}"
            values={{
              helpEmail: <a href="mailto:help@allurion.com">help@allurion.com</a>,
            }}
          />
        </div>
      </div>
    </div>
  );
}
