import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  fallback: JSX.Element;
}>;

export class ErrorBoundary extends React.Component<Props> {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error: any) {
    return {
      hasError: true,
      error,
    };
  }

  render() {
    if (this.state.hasError) {
      return React.cloneElement(this.props.fallback, {
        error: this.state.error,
      });
    }

    return this.props.children;
  }
}
