import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ErrorBoundary } from '../shared/components/ErrorBoundary';
import { Loader } from '../shared/components/Loader';

import { CreatePasswordChangeEmail } from './CreatePasswordChangeEmail';
import { CreatePasswordErrorFallback } from './CreatePasswordErrorFallback';
import { CreatePasswordForm } from './CreatePasswordForm';
import styles from './CreatePasswordPage.module.scss';
import { CreatePasswordSuccess } from './CreatePasswordSuccess';

export function CreatePasswordPage() {
  return (
    <div className={styles.content}>
      <ErrorBoundary fallback={<CreatePasswordErrorFallback />}>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route index element={<CreatePasswordForm />} />
            <Route path="success" element={<CreatePasswordSuccess />} />
            <Route path="change-email" element={<CreatePasswordChangeEmail />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
