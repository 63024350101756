import * as Sentry from '@sentry/react';

export const BASE_URL = import.meta.env.REACT_APP_API_URL ?? 'http://localhost:4004';
const ORIGIN = import.meta.env.REACT_APP_ORIGIN ?? 'http://localhost:3000';

export function getData(pathname: string) {
  return jsonRequest('GET', pathname);
}

export function postData(pathname: string, body: any) {
  return jsonRequest('POST', pathname, {
    body: JSON.stringify(body),
  });
}

function jsonRequest(method: string, pathname: string, options?: RequestInit) {
  return fetch(`${BASE_URL}${pathname}`, {
    method,
    mode: 'cors',
    ...options,
    headers: {
      origin: ORIGIN,
      ...options?.headers,
    },
  }).then(async (res) => {
    let responseBody;

    try {
      responseBody = await res.json();
    } catch (e: any) {
      responseBody = { error: e.toString() };
    }

    // console.log(res);

    if (res.status >= 500) {
      Sentry.captureException(responseBody.error);
    }

    if (res.status >= 400) {
      throw new Error(responseBody.error);
    }

    return responseBody;
  });
}
