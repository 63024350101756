import { FormattedMessage } from 'react-intl';

import styles from './NotFoundPage.module.scss';

export function NotFoundPage() {
  return (
    <div className={styles.content}>
      <p>
        <strong>
          <FormattedMessage
            id="not-found-page.sorry"
            defaultMessage="Sorry, the page could not be found."
          />
        </strong>
      </p>

      <p>
        <FormattedMessage
          id="not-found-page.check-later"
          defaultMessage="Please check the url and try again."
        />
      </p>
    </div>
  );
}
