import { capitalize } from './string';

export const SUPPORTED_LOCALES = [
  'ar',
  'ca',
  'da',
  'de',
  'en_GB',
  'en',
  'es_419',
  'es_ES',
  'fr',
  'it',
  'nl',
  'pt_BR',
  'sv',
  'tr',
  'zh_CN',
  'zh_TW',
] as const;

export type SupportedLocale = (typeof SUPPORTED_LOCALES)[number];

export const fallbackLocales: Record<string, SupportedLocale> = {
  es: 'es_419',
  pt: 'pt_BR',
  zh: 'zh_CN',
};

export function convertBrowserLocale(locale: string): SupportedLocale {
  const language = locale.replace('-', '_');

  if (SUPPORTED_LOCALES.includes(language as SupportedLocale)) {
    return language as SupportedLocale;
  }

  const commonLanguage = language.substring(0, 2);

  if (SUPPORTED_LOCALES.includes(commonLanguage as SupportedLocale)) {
    return commonLanguage as SupportedLocale;
  }

  const fallbackLanguage = fallbackLocales[commonLanguage];

  if (fallbackLanguage) {
    return fallbackLanguage;
  }

  throw new Error(`Unsupported locale: ${locale}`);
}

export function toISOLocale(locale: string) {
  const parts = locale.split(/[-_]/);

  const lang = (parts[0] ?? '').toLocaleLowerCase();

  if (!lang) {
    throw new Error('Invalid locale');
  }

  const country =
    parts.length > 1 ? ((parts.length > 2 ? parts[2] : parts[1]) ?? '').toLocaleUpperCase() : '';

  if (!country) {
    return lang;
  }

  const middle = parts.length > 2 ? capitalize(parts[1] ?? '') : '';

  return [lang, middle, country].filter(Boolean).join('-');
}

const RTL_LOCALES: Record<string, boolean> = {
  ar: true,
  // Add any other Right to left languge here
};

export function isRtlLocale(locale: string) {
  return RTL_LOCALES[locale] ?? false;
}
