import { PropsWithChildren, ReactNode } from 'react';

import styles from './FormGroup.module.scss';

type Props = PropsWithChildren<{
  help?: ReactNode;
  errorMessages?: Record<string, JSX.Element | string>;
  error?: {
    type: string;
    message?: string;
  };
}>;

export function FormGroup({ error, errorMessages, help, children }: Props) {
  return (
    <div className={`${styles.container} ${error ? styles.hasError : ''}`} data-sentry-unmask>
      {children}
      {error ? (
        <small className={styles.help}>{errorMessages?.[error.type] ?? error.message}</small>
      ) : help ? (
        <small className={styles.help}>{help}</small>
      ) : null}
    </div>
  );
}
