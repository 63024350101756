import { FormattedMessage } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { usePatientUser } from '../shared/hooks/usePatientUser';

import styles from './CreatePasswordChangeEmail.module.scss';
import parentStyles from './CreatePasswordPage.module.scss';
import { DownloadAppLinks } from './DownloadAppLinks';

export function CreatePasswordChangeEmail() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const username = searchParams.get('username');
  const { user } = usePatientUser(username);

  return (
    <div className={styles.content}>
      <h1 className={parentStyles.title}>
        <FormattedMessage
          id="create-password-page.change-email-title"
          defaultMessage="To change your email address, please download the app and create a new
          account with your desired email address."
        />
      </h1>

      <p>
        <FormattedMessage
          id="create-password-page.change-email-message"
          defaultMessage="You will need the clinic ID from the email to finish setting up your
          account."
        />
      </p>

      {user && (
        <button type="button" className={styles.backBtn} onClick={() => navigate(-1)}>
          <FormattedMessage
            id="create-password-page.change-email-button"
            defaultMessage="Never mind. I want to use {userEmail}"
            values={{
              userEmail: user.email,
            }}
          />
        </button>
      )}

      <DownloadAppLinks />
    </div>
  );
}
