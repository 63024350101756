import useSWR from 'swr';

import { getData } from '../utils/http';

export function useFetchData<T>(url?: string | null) {
  const { data, error } = useSWR<T>(url, getData, {
    suspense: true,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}
